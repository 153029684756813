import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Seo from '../components/Seo/Seo';
import Image from '../components/Image/Image';
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { formatDate }  from '../tools/formatDate';
import ShareBar from '../components/ShareBar/ShareBar';
import '../scss/main.scss';

// const formatDate = (string) => {
//   const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//   const date = new Date(string);
//   const dateString = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
//   return dateString;
// }

const NewsArticleTemplate = ({data}) => {
    const { title, publishDate, description, image } = data?.contentfulNews;
    const pageTitle = 'News';
    const [loaded, setLoaded] = useState('');
    
    return (
      <>
      <Seo data={data?.contentfulNews}/>
      <Header />
      <main className={`post ${loaded}`}>
        {pageTitle && <h1>{pageTitle}</h1>}
        <div className='content'>
          {image && <Image {...image} onLoad={() => setLoaded('loaded')}/>}
          {title && <h2>{title}</h2>}
          {data?.contentfulNews && <ShareBar {...data?.contentfulNews}/>}
          {publishDate && <h3>{formatDate(publishDate)}</h3>}
          {description && <div className='page-desc'>
            {renderRichText(description)}
          </div>}
        </div>{/* content */}      
      </main>
      <Footer/>
      </>
    )
}

export default NewsArticleTemplate

export const pageQuery = graphql`
  query NewsBySlug($slug: String!) {
    contentfulNews(slug: { eq: $slug }) {
      description {
        raw
      }
      image {
        file {
          url
        }
      }
      slug
      title
      excerpt
      publishDate
      metaTitle
      metaDesc
      metaImage {
        file {
          url
        }
      }
    }
    contentfulPages(slug: { eq: "news" }) {
      slug
      title
      metaTitle
      metaDesc
      metaImage {
        file {
          url
        }
      }
      image {
        file {
          url
        }
      }
      headline
      description {
        raw
      }
    }
  }
`
